import {
  DecoderFunction,
  boolean,
  field,
  number,
  string,
} from 'typescript-json-decoder';

import {
  decodeId,
  deepField,
  forcedArray,
  nullOrUndef,
  recordWithContext,
  stringUnion,
} from '@/utils/decoderUtils';

/**
 *  {
 *   PUMP = 'pump',
 *   READER = 'reader',
 *   SENSOR = 'sensor',
 *   MANUAL = 'manual',
 *   REMOTE_MONITORING = 'remote monitoring',
 * }
 */
export type DeviceType = 'pump' | 'pen' | 'reader' | 'sensor' | 'manual';

export type InsulinPumpProfileSegment = {
  id: string;
  start: string;
  end: string;
  basalRate: number;
  insulinToCarbRatio: number;
  sensitivity: number;
  bloodGlucoseThreshold?: number;
  targetBloodGlucose?: number;
};

export type InsulinPumpProfile = {
  id: string;
  name: string;
  activeInsulinTime?: number;
  currentProfile: boolean;

  segments: InsulinPumpProfileSegment[];
};

export type InsulinPump = {
  profiles: InsulinPumpProfile[];
};

export type MedicalDevice = {
  id: number;
  serialNumber?: string;
  name: string;
  manufacturer: string;
  typeId: number;
  kind: DeviceType;
  lastUpload?: string;
  insulinPump?: InsulinPump;
};

export const insulinPumpProfileSegmentDecoder: DecoderFunction<InsulinPumpProfileSegment> =
  recordWithContext('InsulinPumpProfileSegment', {
    id: decodeId,
    start: field('start_time', string),
    end: field('end_time', string),
    basalRate: field('basal_rate', number),
    insulinToCarbRatio: field('insulin_to_carb_ratio', number),
    sensitivity: field('sensitivity', number),
    bloodGlucoseThreshold: field(
      'blood_glucose_threshold',
      nullOrUndef(number),
    ),
    targetBloodGlucose: field('target_blood_glucose', nullOrUndef(number)),
  });

export const insulinPumpProfileDecoder: DecoderFunction<InsulinPumpProfile> =
  recordWithContext('InsulinPumpProfile', {
    id: string,
    name: string,
    activeInsulinTime: field('active_insulin_time', nullOrUndef(number)),
    currentProfile: field('current_profile', boolean),

    segments: forcedArray(insulinPumpProfileSegmentDecoder),
  });

export const insulinPumpDecoder: DecoderFunction<InsulinPump> =
  recordWithContext('InsulinPump', {
    profiles: forcedArray(insulinPumpProfileDecoder),
  });

export const medicalDeviceDecoder: DecoderFunction<MedicalDevice> =
  recordWithContext('MedicalDevice', {
    id: number,
    serialNumber: field('serial_number', nullOrUndef(string)),
    name: deepField('device_type.name', string),
    manufacturer: deepField('device_type.manufacturer', string),
    kind: deepField(
      'device_type.kind',
      stringUnion<DeviceType>('pump', 'pen', 'reader', 'sensor', 'manual'),
    ),
    typeId: deepField('device_type.id', number),

    lastUpload: field('last_upload', nullOrUndef(string)),
    insulinPump: field('insulin_pump', nullOrUndef(insulinPumpDecoder)),
  });
