import {
  ApiDataSourceSpec,
  apiDataSource,
} from '@/io/datasource/ApiDatasource';
import { ApiErrorResponse } from '@/models/ApiErrorResponse';
import { User, userDecoder } from '@/models/UserModel';
import { Result } from '@/utils/Result';

export class UserRepository {
  constructor(private readonly datasource: ApiDataSourceSpec = apiDataSource) {}

  getUser = (): Promise<Result<User, ApiErrorResponse>> => {
    return this.datasource.get('/me/', userDecoder);
  };

  getFeatureFlags = (): Promise<
    Result<Record<string, boolean>, ApiErrorResponse>
  > => {
    return this.datasource.get(
      '/feature-flags/',
      data => data as Record<string, boolean>,
    );
  };
}
