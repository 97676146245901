import {
  DecoderFunction,
  field,
  number,
  record,
  string,
} from 'typescript-json-decoder';

import {
  intersectionWithContext,
  nullOrUndef,
  recordWithContext,
  stringUnion,
} from '@/utils/decoderUtils';

export type BaseDiabetesDataModel = {
  id: number;
  patientId: string;
  date: string;
};

export const baseDiabetesDataModelDecoder: DecoderFunction<BaseDiabetesDataModel> =
  recordWithContext('BaseDiabetesDataModel', {
    id: number,
    patientId: field('patient_id', string),
    date: string,
  });

export type FoodSize = 'unknown' | 'light' | 'big' | 'medium';

export type Food = {
  size: FoodSize;
  carbs?: number;
  comments?: string;
} & BaseDiabetesDataModel;

export type ActivityIntensity = 'low' | 'medium' | 'high';

export type Activity = {
  intensity: ActivityIntensity;
  duration: number;
} & BaseDiabetesDataModel;

export type ReportCategory =
  | 'severe_hypoglycemia'
  | 'perceived_hypoglycemia'
  | 'perceived_hyperglycemia'
  | 'tech_alert'
  | 'other';

export type Report = {
  category: ReportCategory;
  message: string;
} & BaseDiabetesDataModel;

export const foodDecoder: DecoderFunction<Food> = intersectionWithContext(
  'Food',
  baseDiabetesDataModelDecoder,
  record({
    size: stringUnion<FoodSize>('unknown', 'light', 'big', 'medium'),
    carbs: nullOrUndef(number),
    comments: nullOrUndef(string),
  }),
);

export const activityDecoder: DecoderFunction<Activity> =
  intersectionWithContext(
    'Activity',
    baseDiabetesDataModelDecoder,
    record({
      intensity: stringUnion<ActivityIntensity>('low', 'medium', 'high'),
      duration: number,
    }),
  );

export const reportDecoder: DecoderFunction<Report> = intersectionWithContext(
  'Report',
  baseDiabetesDataModelDecoder,
  record({
    category: stringUnion<ReportCategory>(
      'severe_hypoglycemia',
      'perceived_hypoglycemia',
      'perceived_hyperglycemia',
      'tech_alert',
      'other',
    ),
    message: string,
  }),
);

export type DiabetesParametersType =
  | 'hypoglycemia'
  | 'hyperglycemia'
  | 'severeHypoglycemia'
  | 'severeHyperglycemia';

export type DiabetesParameters = {
  id: string;
} & {
  [K in DiabetesParametersType]: number;
};

export const diabetesParametersDecoder: DecoderFunction<DiabetesParameters> =
  recordWithContext('DiabetesParameters', {
    id: field('patient', string),
    hypoglycemia: field('hypoglycemia', number),
    hyperglycemia: field('hyperglycemia', number),
    severeHypoglycemia: field('severeHypoglycemia', number),
    severeHyperglycemia: field('severeHyperglycemia', number),
  });

export type EditDiabetesParameters = {
  patientId: string;
  id: string;
} & {
  [K in DiabetesParametersType]?: number;
};
