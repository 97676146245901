import { useTheme } from '@mui/material';
import { ScaleLinear } from 'd3-scale';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import {
  Badge,
  badgeWidth,
  badgeWidthSmall,
} from '@/components/data-representations/DailyGraph/Badge';
import { DataTooltip } from '@/components/data-representations/DailyGraph/DataTooltip';
import {
  FoodDataPoint,
  FoodsWithoutOverlaps,
  InsulinContext,
  InsulinWithoutOverlaps,
  ReportsDataPoint,
  ReportsWithoutOverlaps,
} from '@/components/data-representations/DailyGraph/types';
import { Tooltip } from '@/components/floating/Tooltip';
import { FoodSize } from '@/models/DiabetesDataModel';
import { Insulin } from '@/models/InsulinModel';
import { reportCategories } from '@/uiKit/organisms/DailyGraphList/GlycemiaProfiles.tsx';
import { findOverlap, range } from '@/utils/common';
import { toDateTime } from '@/utils/date.ts';
import { toFixedIfNotZero } from '@/utils/math.ts';

type BaseElementProps = {
  xScale: ScaleLinear<number, number>;
  getHour: (date: DateTime | string) => number;
  top: number;
};

type ReportsElementsProps = BaseElementProps & {
  reports: ReportsDataPoint[];
};

const insulinContextMapping = {
  before_meal: 'charts.beforeMeal',
  after_meal: 'charts.afterMeal',
  correction: 'charts.correction',
  instant: 'charts.instant',
  extended: 'charts.extended',
} satisfies Record<InsulinContext, string>;

export const ReportsElements = ({
  reports = [],
  xScale,
  getHour,
  top,
}: ReportsElementsProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const small = true; // small width for badge

  let reportsWithoutOverlaps: ReportsWithoutOverlaps[] = reports || [];

  //find overlap and create Element with multiple reports
  //find overlap index
  const overlaps = findOverlap(
    reportsWithoutOverlaps.map(report => ({
      position: xScale(getHour(report.date ?? '')),
      size: small ? badgeWidthSmall : badgeWidth,
    })),
  );

  //get no overlap report
  reportsWithoutOverlaps = reportsWithoutOverlaps.filter(
    (_, i) => !overlaps.flat().includes(i),
  );
  //create element that contains multiples reports (number is set)
  overlaps.forEach(overlapGroup => {
    const reportsGroup = overlapGroup.map(reportIndex => reports[reportIndex]);
    reportsWithoutOverlaps.push({
      number: reportsGroup.length,
      categories: reportsGroup.map(report => report.category),
      messages: reportsGroup.map(report => report.message),
      dates: reportsGroup.map(report => report.date),
      date:
        toDateTime(
          reportsGroup
            .map(report => toDateTime(report.date))
            .reduce((sum, date) => sum + date.valueOf(), 0) /
            reportsGroup.length, // average of overlap date
        ).toISO() ?? undefined,
    });
  });

  return (
    <>
      {reportsWithoutOverlaps.map((report, i) => {
        const cx = xScale(getHour(report.date ?? ''));

        const badge = (
          <Badge
            key={i}
            text={''}
            top={top}
            left={cx}
            Icon={Icons.event}
            color={theme.palette.error}
            small={small}
            number={report.number}
          />
        );

        return (
          <Tooltip
            key={i}
            svg
            placement="top"
            content={
              <DataTooltip
                data={
                  report.number !== undefined && Number.isInteger(report.number)
                    ? range(report.number ?? 0).map(reportNum => ({
                        dataInputMethod: 'Saisie',
                        title: t(report.categories?.[reportNum] ?? 'other'),
                        content: report.messages?.[reportNum]
                          ? `"${report.messages[reportNum]}"`
                          : '',
                        date: report.dates?.[reportNum],
                      }))
                    : [
                        {
                          dataInputMethod: 'Saisie',
                          title: t(
                            reportCategories[report.category ?? 'other'],
                          ),
                          content: report.message ? `"${report.message}"` : '',
                          date: report.date,
                        },
                      ]
                }
              />
            }
          >
            {badge}
          </Tooltip>
        );
      })}
    </>
  );
};

type FoodElementsProps = BaseElementProps & {
  food: FoodDataPoint[];
};

const FoodSizeTranslations = {
  light: 'charts.lightMeal',
  medium: 'charts.mediumMeal',
  big: 'charts.heavyMeal',
  unknown: 'charts.unknown',
} satisfies Record<FoodSize, string>;

export const FoodElements = ({
  food: foods,
  xScale,
  getHour,
  top,
}: FoodElementsProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const small = true;

  let foodWithoutOverlaps: FoodsWithoutOverlaps[] = foods || [];

  //find overlap and create Element with multiple foods
  //find overlap index
  const overlaps = findOverlap(
    foodWithoutOverlaps.map(food => ({
      position: xScale(getHour(food.date ?? '')),
      size: small ? badgeWidthSmall : badgeWidth,
    })),
  );

  //get no overlap report
  foodWithoutOverlaps = foodWithoutOverlaps.filter(
    (_, i) => !overlaps.flat().includes(i),
  );
  //create element that contains multiples reports (number is set)
  overlaps.forEach(overlapGroup => {
    const foodsGroup = overlapGroup.map(foodIndex => foods[foodIndex]);
    foodWithoutOverlaps.push({
      number: foodsGroup.length,
      sizes: foodsGroup.map(food => food.size),
      carbsList: foodsGroup.map(food => food.carbs ?? 0),
      commentsList: foodsGroup.map(food => food.comments ?? ''),
      dates: foodsGroup.map(food => food.date),
      date:
        toDateTime(
          foodsGroup
            .map(food => toDateTime(food.date))
            .reduce((sum, date) => sum + date.valueOf(), 0) / foodsGroup.length, // average of overlap date
        ).toISO() ?? undefined,
    });
  });

  return (
    <>
      {foodWithoutOverlaps.map((food, i) => {
        const cx = xScale(getHour(food.date ?? ''));
        const badge = (
          <Badge
            key={i}
            text={''}
            top={top}
            left={cx}
            Icon={Icons.meal}
            small={small}
            number={food.number}
            color={theme.palette.green}
          />
        );
        const title = (foodSize?: FoodSize, carbs?: number) =>
          foodSize && foodSize !== 'unknown'
            ? t(FoodSizeTranslations[foodSize])
            : carbs
              ? t('charts.customMeal', { carbs })
              : t('charts.unknownMeal');
        const content = (comment?: string) =>
          comment ? `"${comment}"` : undefined;

        return (
          <Tooltip
            key={i}
            placement="top"
            svg
            content={
              <DataTooltip
                data={
                  food.number !== undefined && Number.isInteger(food.number)
                    ? range(food.number ?? 0).map(foodNum => ({
                        title: title(
                          food.sizes?.[foodNum],
                          food.carbsList?.[foodNum],
                        ),
                        content: content(food.commentsList?.[foodNum]),
                        date: food.dates?.[foodNum],
                        dataInputMethod: t('charts.manual'),
                      }))
                    : [
                        {
                          title: title(food.size, food.carbs),
                          content: content(food.comments),
                          date: food.date,
                          dataInputMethod: t('charts.manual'),
                        },
                      ]
                }
              />
            }
          >
            {badge}
          </Tooltip>
        );
      })}
    </>
  );
};

type InsulinProps = BaseElementProps & {
  insulin: Insulin[];
};

type InsulinShortProps = InsulinProps & {
  title?: string;
};

const getInsulinContext = (context?: InsulinContext): string => {
  return context ? insulinContextMapping[context] : '';
};

const InsulinShort = ({
  insulin: insulins,
  xScale,
  getHour,
  top,
}: InsulinShortProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  let insulinWithoutOverlaps: InsulinWithoutOverlaps[] = insulins || [];

  //find overlap and create Element with multiple foods
  //find overlap index
  const overlaps = findOverlap(
    insulinWithoutOverlaps.map(insulin => ({
      position: xScale(getHour(insulin.date ?? '')),
      size: badgeWidth,
    })),
  );

  //get no overlap report
  insulinWithoutOverlaps = insulinWithoutOverlaps.filter(
    (_, i) => !overlaps.flat().includes(i),
  );
  //create element that contains multiples reports (number is set)
  overlaps.forEach(overlapGroup => {
    const insulinsGroup = overlapGroup.map(
      insulinIndex => insulins[insulinIndex],
    );
    insulinWithoutOverlaps.push({
      number: insulinsGroup.length,
      quantities: insulinsGroup.map(insulin => insulin.quantity),
      dates: insulinsGroup.map(insulin => insulin.date),
      date:
        toDateTime(
          insulinsGroup
            .map(insulin => toDateTime(insulin.date))
            .reduce((sum, date) => sum + date.valueOf(), 0) /
            insulinsGroup.length, // average of overlap date
        ).toISO() ?? undefined,
    });
  });

  return (
    <>
      {insulinWithoutOverlaps.map((insulin, i) => {
        const cx = xScale(getHour(insulin.date ?? '')) - badgeWidth / 2;
        const badge = (
          <Badge
            key={i}
            text={`${
              insulin.number != undefined && Number.isInteger(insulin.number)
                ? toFixedIfNotZero(
                    (insulin.quantities ?? []).reduce((sum, nb) => sum + nb),
                  )
                : toFixedIfNotZero(insulin.quantity)
            } U`}
            top={top}
            left={cx}
            Icon={Icons.injection}
            color={theme.palette.insulin.bolus}
            number={insulin.number}
          />
        );
        const insulinContext = getInsulinContext(insulin.context);
        return (
          <Tooltip
            key={i}
            placement="top"
            svg
            content={
              <DataTooltip
                data={
                  insulin.number != undefined &&
                  Number.isInteger(insulin.number)
                    ? range(insulin.number ?? 0).map(insulinNum => ({
                        title: `${toFixedIfNotZero(insulin.quantities?.[insulinNum])} U`,
                        content: `${t(
                          'pages.patientMonitoring.insulin.fast',
                        )}\n${insulinContext ? t(insulinContext) : ''}`,
                        date: insulin.dates?.[insulinNum] ?? undefined,
                        dataInputMethod: 'Saisie',
                      }))
                    : [
                        {
                          title: `${toFixedIfNotZero(insulin.quantity)} U`,
                          content: `${t(
                            'pages.patientMonitoring.insulin.fast',
                          )}\n${insulinContext ? t(insulinContext) : ''}`,
                          date: insulin.date,
                          dataInputMethod: 'Saisie',
                        },
                      ]
                }
              />
            }
          >
            {badge}
          </Tooltip>
        );
      })}
    </>
  );
};

type InsulinBasalProps = Omit<InsulinBasalBolusElementsProps, 'topBolus'>;

const InsulinBasal = ({
  insulin: insulins,
  xScale,
  getHour,
  topBasal,
}: InsulinBasalProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  let insulinWithoutOverlaps: InsulinWithoutOverlaps[] = insulins || [];

  //find overlap and create Element with multiple foods
  //find overlap index
  const overlaps = findOverlap(
    insulinWithoutOverlaps.map(insulin => ({
      position: xScale(getHour(insulin.date ?? '')),
      size: badgeWidth,
    })),
  );

  //get no overlap report
  insulinWithoutOverlaps = insulinWithoutOverlaps.filter(
    (_, i) => !overlaps.flat().includes(i),
  );
  //create element that contains multiples reports (number is set)
  overlaps.forEach(overlapGroup => {
    const insulinsGroup = overlapGroup.map(
      insulinIndex => insulins[insulinIndex],
    );
    insulinWithoutOverlaps.push({
      number: insulinsGroup.length,
      types: insulinsGroup.map(insulin => insulin.type),
      quantities: insulinsGroup.map(insulin => insulin.quantity),
      dates: insulinsGroup.map(insulin => insulin.date),
      date:
        toDateTime(
          insulinsGroup
            .map(insulin => toDateTime(insulin.date))
            .reduce((sum, date) => sum + date.valueOf(), 0) /
            insulinsGroup.length, // average of overlap date
        ).toISO() ?? undefined,
    });
  });

  return (
    <>
      {insulinWithoutOverlaps.map((insulin, i) => {
        const cx = xScale(getHour(insulin.date ?? '')) - badgeWidth / 2;
        const badge = (
          <Badge
            key={i}
            text={`${
              insulin.number != undefined && Number.isInteger(insulin.number)
                ? toFixedIfNotZero(
                    (insulin.quantities ?? []).reduce((sum, nb) => sum + nb),
                  )
                : toFixedIfNotZero(insulin.quantity)
            } U`}
            top={topBasal}
            left={cx}
            Icon={Icons.injection}
            color={theme.palette.insulin.basal}
            number={insulin.number}
          />
        );
        return (
          <Tooltip
            key={i}
            placement="top"
            svg
            content={
              <DataTooltip
                data={
                  insulin.number != undefined &&
                  Number.isInteger(insulin.number)
                    ? range(insulin.number ?? 0).map(insulinNum => ({
                        title: `${toFixedIfNotZero(insulin.quantities?.[insulinNum])} U`,
                        content: `${
                          insulin.types?.[insulinNum] == 'long'
                            ? t('insulin.slow')
                            : t('insulin.fast')
                        }`,
                        date: insulin.dates?.[insulinNum] ?? undefined,
                        dataInputMethod: 'Saisie',
                      }))
                    : [
                        {
                          title: `${toFixedIfNotZero(insulin.quantity)} U`,
                          content: `${
                            insulin.type == 'long'
                              ? t('insulin.slow')
                              : t('insulin.fast')
                          }`,
                          date: insulin.date,
                          dataInputMethod: 'Saisie',
                        },
                      ]
                }
              />
            }
          >
            {badge}
          </Tooltip>
        );
      })}
    </>
  );
};

const InsulinBolus = (props: InsulinShortProps) => <InsulinShort {...props} />;

type InsulinBasalBolusElementsProps = InsulinElementProps;

const InsulinBasalBolusElements = ({
  insulin,
  xScale,
  getHour,
  topBolus,
  topBasal,
  end,
}: InsulinBasalBolusElementsProps) => {
  const insulinBasal = insulin.filter(e => e.reason === 'basal');
  const insulinBolus = insulin.filter(e => e.reason === 'bolus');
  return (
    <>
      <InsulinBolus
        {...{
          insulin: insulinBolus,
          xScale,
          getHour,
          top: topBolus,
        }}
      />
      <InsulinBasal
        {...{
          insulin: insulinBasal,
          xScale,
          getHour,
          topBasal,
          end,
        }}
      />
    </>
  );
};

type InsulinElementProps = Omit<BaseElementProps, 'top'> & {
  insulin: Insulin[];
  topBolus: number;
  topBasal: number;
  end: number;
};

export const InsulinElements = (props: InsulinElementProps) => (
  <InsulinBasalBolusElements {...props} />
);
