import React from 'react';

import { useTheme } from '@mui/material';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { BarRounded } from '@visx/shape';
import { Text } from '@visx/text';

import { TargetDurationStats } from '@/models/TargetDurationStatsModel';

type Data = TargetDurationStats['reader' | 'sensor']['glycemia_distribution'];

const defaultMargin = { top: 0, left: 100, right: 60, bottom: 0 };

// accessors
const getGlycemiaRange = (d: Data[number]) => d.glycemia_range;
const getRatio = (d: Data[number]) => Number(d.ratio);

type GlycemiaBarsProps = {
  data: Data;
  width: number;
  height: number;
  margin?: typeof defaultMargin;
  className?: string;
};

export const GlycemiaBars: React.FC<GlycemiaBarsProps> = ({
  data,
  width,
  height,
  margin = defaultMargin,
  className = '',
}) => {
  // bounds
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  const theme = useTheme();

  const fillColorScale = [
    theme.palette.glycemia.severeHyperglycemia.contrastText,
    theme.palette.glycemia.hyperglycemia.contrastText,
    theme.palette.glycemia.normal.contrastText,
    theme.palette.glycemia.hypoglycemia.contrastText,
    theme.palette.glycemia.severeHypoglycemia.contrastText,
  ];

  const borderColorScale = [
    theme.palette.glycemia.severeHyperglycemia.light,
    theme.palette.glycemia.hyperglycemia.light,
    theme.palette.glycemia.normal.light,
    theme.palette.glycemia.hypoglycemia.light,
    theme.palette.glycemia.severeHypoglycemia.light,
  ];

  const textColorScale = [
    theme.palette.glycemia.severeHyperglycemia.main,
    theme.palette.glycemia.hyperglycemia.main,
    theme.palette.glycemia.normal.main,
    theme.palette.glycemia.hypoglycemia.main,
    theme.palette.glycemia.severeHypoglycemia.main,
  ];

  // scales, memoize for performance
  const yScale = React.useMemo(
    () =>
      scaleBand({
        range: [0, yMax],
        round: true,
        domain: data.map(getGlycemiaRange),
        padding: 0.7,
      }),
    [yMax, data],
  );
  const xScale = React.useMemo(
    () =>
      scaleLinear({
        range: [0, xMax],
        round: true,
        domain: [0, Math.max(...data.map(getRatio))],
      }),
    [xMax, data],
  );

  const springs = data.map(d => ({
    barWidth: xScale(getRatio(d)),
    labelX: xScale(getRatio(d)) + 10,
  }));

  return (
    <div className={className}>
      <svg width={width} height={height}>
        <Group top={margin.top} left={margin.left}>
          {springs.map(({ barWidth, labelX }, index) => {
            const letter = getGlycemiaRange(data[index]);
            const barHeight = yScale.bandwidth();
            const barX = 0;
            const barY = yScale(letter);
            return (
              <Group key={`bar-group-${letter}`}>
                <BarRounded
                  key={`bar-${letter}`}
                  x={barX}
                  y={barY ?? 0}
                  width={barWidth}
                  height={barHeight}
                  fill={fillColorScale[index]}
                  stroke={borderColorScale[index]}
                  strokeWidth="1px"
                  radius={2}
                  all={true}
                />
                <Text
                  key={`bar-data-${letter}`}
                  x={labelX}
                  y={(barY ?? 0) + barHeight}
                  fontSize={16}
                  fill={textColorScale[index]}
                >
                  {getRatio(data[index]) + '%'}
                </Text>
                <Text
                  key={`bar-legend-${letter}`}
                  x={barX - 10}
                  y={(barY ?? 0) + barHeight}
                  fontSize={12}
                  fill={theme.palette.grey[800]}
                  textAnchor="end"
                >
                  {getGlycemiaRange(data[index])}
                </Text>
              </Group>
            );
          })}
        </Group>
      </svg>
    </div>
  );
};
