import React from 'react';

import { Route, Routes } from 'react-router-dom';

import { NotFound } from '@/pages/NotFound';
import { HealthProAccountPage } from '@/pages/account/HealthProAccountPage';
import { MeasurementImportPage } from '@/pages/add-measurement/MeasurementImportPage';
import { HealthProLayout } from '@/pages/layout/HealthProLayout';
import { HealthProManagePatientLayout } from '@/pages/layout/HealthProManagePatientLayout';
import { PatientMedicalFile } from '@/pages/patient-medical-file/PatientMedicalFile';
import { PatientMonitoring } from '@/pages/patient-monitoring/PatientMonitoring';
import PatientSettings from '@/pages/patient-settings/PatientSettings';
import { PatientsPage } from '@/pages/patients/PatientsPage';
import { Queries } from '@/queries/Queries';
import { ImpersonatingBanner } from '@/uiKit/molecules/ImpersonationBanner';

export type HealthProRouterProps = {
  // Empty
};

export const PractitionerRouter: React.FC<HealthProRouterProps> = () => {
  Queries.user.useFeatureFlags({}); // prefetch feature flags

  return (
    <>
      <ImpersonatingBanner />
      <Routes>
        <Route path="/" element={<PatientsPage />} />
        <Route path="/patients" element={<PatientsPage />} />
        <Route path="/account" element={<HealthProAccountPage />} />
        <Route
          path="/patients/:id"
          element={<HealthProManagePatientLayout outlet />}
        >
          <Route index element={<PatientMonitoring />} />
          <Route path="medical-file" element={<PatientMedicalFile />} />
          <Route path="upload-data" element={<MeasurementImportPage />} />
          <Route path="settings" element={<PatientSettings />} />
        </Route>
        <Route
          path="*"
          element={
            <HealthProLayout>
              <NotFound />
            </HealthProLayout>
          }
        />
      </Routes>
    </>
  );
};
