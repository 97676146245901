import {
  DecoderFunction,
  field,
  number,
  string,
} from 'typescript-json-decoder';

import { recordWithContext, stringUnion } from '@/utils/decoderUtils.ts';

/***********************
 *   Glycemia types    *
 ***********************/

export type GlycemiaMeasureType = 'interstitial' | 'capillary';
export type GlycemiaPeriod = 'breakfast' | 'lunch' | 'dinner' | 'before_bed';
export type GlycemiaPeriodTiming = 'before' | 'after';

export type Glycemia = {
  id: number;
  patientId: string;
  date: string;

  type: GlycemiaMeasureType;
  period: GlycemiaPeriod;
  periodTiming: GlycemiaPeriodTiming;
  value: number;
};

export type ReaderGlycemia = Omit<Glycemia, 'type'> & {
  type: 'capillary';
};

export type SensorGlycemia = Omit<Glycemia, 'type'> & {
  type: 'interstitial';
};

export type SplitGlycemiaData = {
  reader: ReaderGlycemia[];
  sensor: SensorGlycemia[];
};

/***********************
 *  Glycemia decoders  *
 ***********************/

export const glycemiaDecoder: DecoderFunction<Glycemia> = recordWithContext(
  'Glycemia',
  {
    id: number,
    patientId: field('patient_id', string),
    date: string,
    type: stringUnion('interstitial', 'capillary'),
    period: stringUnion('breakfast', 'lunch', 'dinner', 'before_bed'),
    periodTiming: field('period_timing', stringUnion('before', 'after')),
    value: number,
  },
);
