import React from 'react';

import CE from '@/assets/icons/CE.svg?react';
import Activity from '@/assets/icons/activity.svg?react';
import AlertCircle from '@/assets/icons/alert-circle.svg?react';
import AlertTriangle from '@/assets/icons/alert-triangle.svg?react';
import ArrowLeft from '@/assets/icons/arrow-left.svg?react';
import ArrowRight from '@/assets/icons/arrow-right.svg?react';
import Bell from '@/assets/icons/bell.svg?react';
import Calendar from '@/assets/icons/calendar.svg?react';
import Check from '@/assets/icons/check.svg?react';
import ChevronDown from '@/assets/icons/chevron-down.svg?react';
import ChevronLeft from '@/assets/icons/chevron-left.svg?react';
import ChevronRight from '@/assets/icons/chevron-right.svg?react';
import ChevronUp from '@/assets/icons/chevron-up.svg?react';
import Clock from '@/assets/icons/clock.svg?react';
import Download from '@/assets/icons/download.svg?react';
import Edit from '@/assets/icons/edit.svg?react';
import EditNote from '@/assets/icons/edit_note.svg?react';
import Email from '@/assets/icons/email.svg?react';
import Empty from '@/assets/icons/empty-key.svg?react';
import Event from '@/assets/icons/event.svg?react';
import externalLink from '@/assets/icons/external-link.svg?react';
import Eye from '@/assets/icons/eye.svg?react';
import FilePlus from '@/assets/icons/file-plus.svg?react';
import FileUpload from '@/assets/icons/file-upload.svg?react';
import Forward from '@/assets/icons/forward.svg?react';
import FullScreen from '@/assets/icons/full-screen.svg?react';
import Hamburger from '@/assets/icons/hamburger.svg?react';
import CloseBold from '@/assets/icons/ic-close-bold.svg?react';
import Close from '@/assets/icons/ic-close.svg?react';
import Mail from '@/assets/icons/ic-mail.svg?react';
import Phone from '@/assets/icons/ic-phone.svg?react';
import ThreePointMenu from '@/assets/icons/ic-three-point-menu.svg?react';
import EmptyTickBox from '@/assets/icons/ic-tick-empty.svg?react';
import TickBox from '@/assets/icons/ic-tick.svg?react';
import Info from '@/assets/icons/info.svg?react';
import Information from '@/assets/icons/information.svg?react';
import Injection from '@/assets/icons/injection.svg?react';
import InsulinPen from '@/assets/icons/insulin_pen.svg?react';
import Letter from '@/assets/icons/letter.svg?react';
import LogoFull from '@/assets/icons/logo-steto-full.svg?react';
import LogoLightFull from '@/assets/icons/logo-steto-light-full.svg?react';
import LogoLight from '@/assets/icons/logo-steto-light.svg?react';
import LogoRoundFull from '@/assets/icons/logo-steto-round-full.svg?react';
import LogoRound from '@/assets/icons/logo-steto-round.svg?react';
import Logo from '@/assets/icons/logo-steto.svg?react';
import Manual from '@/assets/icons/manual.svg?react';
import Meal from '@/assets/icons/meal.svg?react';
import Apple from '@/assets/icons/meal.svg?react';
import PhoneOutline from '@/assets/icons/phone.svg?react';
import Plus from '@/assets/icons/plus.svg?react';
import Pump from '@/assets/icons/pump.svg?react';
import QuestionCircle from '@/assets/icons/question-circle.svg?react';
import Question from '@/assets/icons/question.svg?react';
import Reader from '@/assets/icons/reader.svg?react';
import Search from '@/assets/icons/search.svg?react';
import SendMessage from '@/assets/icons/send-message.svg?react';
import Sensor from '@/assets/icons/sensor.svg?react';
import SmallArrowDown from '@/assets/icons/small-arrow-down.svg?react';
import SmallArrowUp from '@/assets/icons/small-arrow-up.svg?react';
import Stethoscope from '@/assets/icons/stethoscope.svg?react';
import Trash from '@/assets/icons/trash.svg?react';
import UserPlus from '@/assets/icons/user-plus.svg?react';
import Users from '@/assets/icons/users.svg?react';

export type IconType = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

/**
 *
 * This file is used to import all the icons in the project and export them as a single object.
 *
 * Rationale:
 * Importing with { ReactComponent as IconName } from 'path/to/icon.svg' is too verbose.
 * So we import all the icons in this file and export them as a single object.
 * It enforces consistency and type safety.
 */
export const Icons = {
  activity: Activity,
  alertCircle: AlertCircle,
  alertTriangle: AlertTriangle,
  arrowDown: SmallArrowDown,
  apple: Apple,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  arrowUp: SmallArrowUp,
  bell: Bell,
  calendar: Calendar,
  ce: CE,
  check: Check,
  chevronDown: ChevronDown,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  chevronUp: ChevronUp,
  clock: Clock,
  closeBoldIcon: CloseBold,
  closeIcon: Close,
  download: Download,
  edit: Edit,
  editNote: EditNote,
  email: Email,
  empty: Empty,
  emptyTickBox: EmptyTickBox,
  event: Event,
  externalLink: externalLink,
  eye: Eye,
  filePlus: FilePlus,
  fileUpload: FileUpload,
  forward: Forward,
  fullscreen: FullScreen,
  hamburger: Hamburger,
  information: Information,
  injection: Injection,
  info: Info,
  letter: Letter,
  logo: Logo,
  logoFull: LogoFull,
  logoLight: LogoLight,
  logoLightFull: LogoLightFull,
  logoRound: LogoRound,
  logoRoundFull: LogoRoundFull,
  mail: Mail,
  manual: Manual,
  meal: Meal,
  insulinPen: InsulinPen,
  phone: Phone,
  phoneOutline: PhoneOutline,
  plus: Plus,
  pump: Pump,
  question: Question,
  questionCircle: QuestionCircle,
  reader: Reader,
  search: Search,
  sendMessage: SendMessage,
  sensor: Sensor,
  stethoscope: Stethoscope,
  threePointMenu: ThreePointMenu,
  tickBox: TickBox,
  trash: Trash,
  userPlus: UserPlus,
  users: Users,
} satisfies { [key: string]: IconType };
