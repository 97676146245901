import { InsulinRepository } from '@/io/repository/InsulinRepository.ts';
import { makeQueryKey, stripQueryResult } from '@/queries/Queries';
import { createQuery } from '@/queries/utils/CreateQuery';
import { DiabetesDataParams } from '@/queries/utils/DiabetesQueryUtils.ts';

export class InsulinQueries {
  constructor(
    private readonly diabetes: InsulinRepository = new InsulinRepository(),
  ) {}

  /*******************************/
  /*********** QUERIES ***********/
  /*******************************/

  useInsulinStats = createQuery(
    'insulin-stats',
    ({ patientId, from, to }) =>
      makeQueryKey(patientId, from?.toISODate(), to?.toISODate()),
    async ({ patientId, from, to }: DiabetesDataParams) =>
      stripQueryResult(
        await this.diabetes.getInsulinStats(patientId, from, to),
      ),
  );
}
