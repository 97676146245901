import React, { MutableRefObject, useLayoutEffect, useRef } from 'react';

export type ComponentSize = {
  width: number;
  height: number;
};

export const useComponentSize = (): [
  MutableRefObject<HTMLElement | null>,
  ComponentSize,
] => {
  const componentRef = useRef<HTMLElement>(null);
  const [componentSize, setComponentSize] = React.useState<ComponentSize>({
    width: 0,
    height: 0,
  });

  useLayoutEffect(() => {
    const handleResize = () => {
      const component = componentRef.current;
      if (component) {
        setComponentSize({
          width: component.clientWidth,
          height: component.clientHeight,
        });
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return [componentRef, componentSize];
};
