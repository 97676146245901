import React from 'react';

import { css } from '@emotion/css';
import { PaletteColor, Theme } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';

export type DotProps = {
  color: PaletteColor;
};

export const Dot: React.FC<DotProps> = ({ color }) => {
  const styles = useStyles(makeLegendDotStyles, color);

  return <div className={styles.item} />;
};

const makeLegendDotStyles = (theme: Theme, color: PaletteColor) => ({
  item: css`
    width: ${theme.spacing(8)};
    height: ${theme.spacing(8)};
    border-radius: 100%;
    border: ${theme.spacing(0.5)} solid ${color.main};
    background-color: ${color.contrastText};
  `,
});
