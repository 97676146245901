import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';

import { ShowApiError } from '@/components/error/ShowApiError';
import { Col } from '@/components/layout/Flex';
import { Loader } from '@/components/loading/Loader';
import { ActiveNotifications } from '@/components/notifications/ActiveNotifications';
import { useQueryDateRange } from '@/hooks/query/useQueryDateRange.ts';
import { usePatientIdFromURL } from '@/hooks/usePatientIdFromURL.ts';
import { useStyles } from '@/hooks/useStyles';
import { useWindowSize } from '@/hooks/useWindowSize';
import HeaderMonitoring from '@/pages/patient-monitoring/HeaderMonitoring';
import { MonitoringAside } from '@/pages/patient-monitoring/MonitoringAside.tsx';
import { MonitoringDailyData } from '@/pages/patient-monitoring/MonitoringDailyData.tsx';
import { MonitoringStats } from '@/pages/patient-monitoring/MonitoringStats.tsx';
import { getDateRangeAndPeriodicity } from '@/pages/patient-monitoring/PatientMonitoringPeriod.ts';
import {
  PatientMonitoringProvider,
  useListenToQueryDateRange,
} from '@/pages/patient-monitoring/PatientMonitoringState.tsx';
import { Queries } from '@/queries/Queries';

export const PatientMonitoring: React.FC = () => {
  const patientId = usePatientIdFromURL();
  const queryData = useQueryDateRange();
  const patientQuery = Queries.practitioner.usePatient(patientId ?? '', {
    enabled: !!patientId,
  });
  const styles = useStyles(makeStyles);

  switch (patientQuery.status) {
    case 'error':
      return (
        <ShowApiError errorMapping={{}} error={patientQuery.error.error} />
      );
    case 'pending':
      return (
        <Col className={styles.loading}>
          <Loader size="L" />
        </Col>
      );
    case 'success': {
      const periodAndRange = getDateRangeAndPeriodicity(queryData);
      return (
        <PatientMonitoringProvider
          periodicity={periodAndRange.periodicity}
          from={periodAndRange.from}
          to={periodAndRange.to}
          patient={patientQuery.data}
        >
          <PatientMonitoringContent />
        </PatientMonitoringProvider>
      );
    }
  }
};

const PatientMonitoringContent: React.FC = () => {
  const { screenSize } = useWindowSize();
  const styles = useStyles(makeStyles);
  useListenToQueryDateRange();

  switch (screenSize) {
    case 'SM':
    case 'MD':
    case 'LG':
      return (
        <div className={styles.container}>
          <div className={styles.content}>
            <HeaderMonitoring />
            <MonitoringStats />
            <div className={styles.inlinedAside}>
              <div className={styles.asideContent}>
                <MonitoringAside />
              </div>
              <ActiveNotifications className={styles.asideContent} />
            </div>
            <MonitoringDailyData />
          </div>
        </div>
      );
    case 'XL':
    case 'XXL':
      return (
        <div className={styles.container}>
          <div className={styles.content}>
            <HeaderMonitoring />
            <MonitoringStats />
            <MonitoringDailyData />
          </div>

          <aside className={styles.aside}>
            <MonitoringAside />
            <ActiveNotifications />
          </aside>
        </div>
      );
  }
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    gap: ${theme.spacing(8)};
    margin-bottom: ${theme.spacing(12)};
  `,
  content: css`
    display: flex;
    flex-direction: column;
    flex: 1 1;
    gap: ${theme.spacing(8)};
    min-width: 0;
  `,
  aside: css`
    display: flex;
    flex-direction: column;
    flex: 0 1 300px;
    gap: ${theme.spacing(8)};
    padding-top: ${theme.spacing(28)};
  `,
  inlinedAside: css`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing(8)};
    flex-wrap: wrap;
  `,
  asideContent: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(8)};
    flex: 1 1 0;
    height: fit-content;
  `,
  loading: css`
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  `,
});
