import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { CardBody } from '@/components/card/CardBody';
import { TextCardTitle } from '@/components/card/CardTitle';
import { useStyles } from '@/hooks/useStyles';
import { LanguageSelector } from '@/uiKit/molecules/LanguageSelector.tsx';

export type LanguageProps = {
  className?: string;
};

export const Language: React.FC<LanguageProps> = ({ className }) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();

  return (
    <Card className={className} elevation={0}>
      <TextCardTitle
        id="language-title"
        title={t('pages.account.language.title')}
        onClick={() => {
          throw new Error('This is your first error!');
        }}
      />
      <CardBody>
        <LanguageSelector className={styles.language} display="full" />
      </CardBody>
    </Card>
  );
};

const makeStyles = (theme: Theme) => ({
  infoDisplay: css`
    column-gap: ${theme.spacing(12)};
  `,
  language: css({
    width: 'fit-content',
  }),
});
