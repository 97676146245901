import * as Sentry from '@sentry/react';

function checkEnvironment(url: string) {
  const regex = /app\.(staging[0-9]\.)?steto\.care/;
  const match = url.match(regex);

  if (!match) {
    return 'dev'; // No match
  } else if (!match[1]) {
    return 'prod'; // Matched but no staging part (empty string)
  } else {
    return match[1].slice(0, -1); // Return the matched staging part without the trailing dot
  }
}

export const SentryInit = () => {
  const env = checkEnvironment(window.origin);

  Sentry.init({
    dsn: 'https://0f540975860113a84a974250313c9269@o4508239595962368.ingest.de.sentry.io/4508251433992272',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    release: import.meta.env['VITE_APP_RELEASE_NAME'],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/app.(staging[0-9]\.){0,1}steto\.care/,
    ],
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
    denyUrls: ['localhost:8080', 'auth.steto.care'],
    environment: env,
  });

  return null;
};
