import React from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Queries } from '@/queries/Queries';

import { InformationPaper } from '../atoms/InformationPaper';

export type DoctorChoseNotToRenewCardProps = {
  patientId: string;
};

export const DoctorChoseNotToRenewCard: React.FC<
  DoctorChoseNotToRenewCardProps
> = ({ patientId }) => {
  const { t } = useTranslation();

  const { data: carePlanData } = Queries.practitioner.useCarePlans(patientId);
  const { data: practitioner } = Queries.practitioner.usePractitioner({});

  if (
    practitioner?.qualification !== 'nurse' ||
    !carePlanData?.has_doctor_decided_not_to_renew
  ) {
    return null;
  }

  return (
    <InformationPaper isSquare={true} padding={12}>
      <Typography variant="bodySmall">
        {t('patientInfo.doctorDecidedNotToRenew')}
      </Typography>
    </InformationPaper>
  );
};
