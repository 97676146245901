import React from 'react';

import { css } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Icons } from '@/assets/icons';
import { IconButton } from '@/components/button/IconButton';
import { Card } from '@/components/card/Card';
import { CardBody } from '@/components/card/CardBody';
import { CardTitle } from '@/components/card/CardTitle';
import { useStyles } from '@/hooks/useStyles';
import { PatientsTagsList } from '@/pages/patients/PatientsTagsList';
import { Queries } from '@/queries/Queries';
import { RenewalButton } from '@/uiKit/molecules/RenewalButton';

type RPMInfoProps = {
  patientId: string;
  className?: string;
};

export const RPMInfo: React.FC<RPMInfoProps> = ({ patientId, className }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const { data: carePlanData } = Queries.practitioner.useCarePlans(patientId);
  const { data: practitionerData } = Queries.practitioner.usePractitioner({});
  const isDoctor = practitionerData?.qualification === 'doctor';
  if (!carePlanData) {
    return null;
  }

  return (
    <Card elevation={0} className={className}>
      <CardTitle style={{ justifyContent: 'space-between' }}>
        <Typography variant="h6">
          {t('diabetesForms.telemonitoring')}
        </Typography>
        <div>
          <IconButton
            style={{ marginRight: 0, marginLeft: 0, paddingRight: 0 }}
            icon={Icons.externalLink}
            buttonType="link"
            placement="left"
            onClick={() => navigate(`/patients/${patientId}/settings`)}
          />
        </div>
      </CardTitle>
      <CardBody>
        <div className={styles.container}>
          <PatientsTagsList
            telemonitoringTags={carePlanData.telemonitoring_tags}
          />
          {isDoctor && (
            <div>
              <RenewalButton patientId={patientId} />
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(12)};
  `,
});
