import {
  DecoderFunction,
  boolean,
  field,
  literal,
  number,
  string,
  union,
} from 'typescript-json-decoder';

import {
  deepField,
  nullOrUndef,
  recordWithContext,
  stringUnion,
} from '@/utils/decoderUtils.ts';

/***********************
 *    Insulin types    *
 ***********************/

export type InsulinType = 'long' | 'short';
export type InsulinReason = 'bolus' | 'basal';
export type InsulinPeriod = 'breakfast' | 'lunch' | 'dinner';
export type InsulinMethod = 'pen' | 'pump';
export type InsulinMode = 'discrete' | 'continuous';

export type Insulin = {
  id: number;
  patientId: string;
  date: string;

  quantity: number;
  type: InsulinType;
  period: InsulinPeriod;
  reason: InsulinReason;
  method: InsulinMethod;
  correction: boolean;
  iob?: number;
};

export type DiscreteBasalInsulin = Omit<Insulin, 'type' | 'reason'> & {
  type: 'long';
  reason: 'basal';
};

export type ContinuousBasalInsulin = Omit<Insulin, 'type' | 'reason'> & {
  type: 'short';
  reason: 'basal';
};

export type BolusInsulin = Omit<Insulin, 'type' | 'reason'> & {
  type: 'short';
  reason: 'bolus';
};

export type SplitInsulinData = {
  continuous: ContinuousBasalInsulin[];
  discrete: DiscreteBasalInsulin[];
  bolus: BolusInsulin[];
};

export type DiscreteInsulinStats = {
  type: 'discrete';
  start: string;
  end: string;
  nbDays: number;
  meanCountInjections: number;
  meanShortInsulin: number;
  meanLongInsulin: number;
  meanTotalInsulin: number;
};

export type ContinuousInsulinStats = {
  type: 'continuous';
  start: string;
  end: string;
  nbDays: number;
  meanBolusInjections: number;
  meanBasalInsulin: number;
  meanBolusInsulin: number;
  meanTotalInsulin: number;
};

export type InsulinStats = DiscreteInsulinStats | ContinuousInsulinStats;

/***********************
 *   Insulin decoders  *
 ***********************/

export const insulinDecoder: DecoderFunction<Insulin> = recordWithContext(
  'Insulin',
  {
    id: number,
    patientId: field('patient_id', string),
    date: string,
    quantity: number,
    type: stringUnion<InsulinType>('long', 'short'),
    reason: stringUnion<InsulinReason>('bolus', 'basal'),
    period: stringUnion<InsulinPeriod>('breakfast', 'lunch', 'dinner'),
    method: stringUnion<InsulinMethod>('pen', 'pump'),
    correction: boolean,
    iob: nullOrUndef(number),
  },
);

export const continuousDailyInsulinStatsDecoder: DecoderFunction<ContinuousInsulinStats> =
  recordWithContext('ContinuousInsulinStats', {
    type: literal('continuous'),
    start: string,
    end: string,
    nbDays: field('nb_days', number),
    meanBolusInjections: deepField('continuous.mean_bolus_injections', number),
    meanBasalInsulin: deepField('continuous.mean_basal_insulin', number),
    meanBolusInsulin: deepField('continuous.mean_bolus_insulin', number),
    meanTotalInsulin: deepField('continuous.mean_total_insulin', number),
  });

export const discreteDailyInsulinStatsDecoder: DecoderFunction<DiscreteInsulinStats> =
  recordWithContext('DiscreteInsulinStats', {
    type: literal('discrete'),
    start: string,
    end: string,
    nbDays: field('nb_days', number),
    meanCountInjections: deepField('discrete.mean_count_injections', number),
    meanShortInsulin: deepField('discrete.mean_short_insulin', number),
    meanLongInsulin: deepField('discrete.mean_long_insulin', number),
    meanTotalInsulin: deepField('discrete.mean_total_insulin', number),
  });

export const insulinStatsDecoder: DecoderFunction<InsulinStats> = union(
  discreteDailyInsulinStatsDecoder,
  continuousDailyInsulinStatsDecoder,
);
