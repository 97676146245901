import React from 'react';

import { css } from '@emotion/css';
import { Theme, useTheme } from '@mui/material';
import { Group } from '@visx/group';
import Pie from '@visx/shape/lib/shapes/Pie';

import { useComponentSize } from '@/hooks/useComponentSize.ts';

export type InsulinStatsWheelProps = {
  basal: number;
  bolus: number;
  className?: string;
};

export const InsulinStatsWheel: React.FC<InsulinStatsWheelProps> = ({
  basal,
  bolus,
  className,
}) => {
  const [divRef, { width, height }] = useComponentSize();

  return (
    <div
      ref={instance => {
        divRef.current = instance;
      }}
      className={className}
    >
      <InsulinStatsWheelSvg
        width={width}
        height={height}
        bolus={bolus}
        basal={basal}
      />
    </div>
  );
};

type InsulinStatsWheelSvgProps = {
  width: number;
  height: number;
} & InsulinStatsWheelProps;

type PieArcData = {
  label: 'basal' | 'bolus';
  ratio: number;
};

const InsulinStatsWheelSvg: React.FC<InsulinStatsWheelSvgProps> = ({
  width,
  height,
  bolus,
  basal,
}) => {
  const theme = useTheme();
  const size = Math.min(width, height);

  if (size <= 0) {
    return null;
  }

  const radius = (Math.min(width, height) / 2) * 0.95;
  const centerY = size / 2;
  const centerX = size / 2;
  const donutThickness = radius * 0.2;
  const total = basal + bolus;
  const data: PieArcData[] = [
    {
      label: 'basal',
      ratio: total > 0 ? basal / total : 0,
    },
    {
      label: 'bolus',
      ratio: total > 0 ? bolus / total : 0,
    },
  ];

  return (
    <svg height={size} width={size}>
      <Group top={centerY} left={centerX}>
        <Pie<PieArcData>
          outerRadius={radius}
          innerRadius={radius - donutThickness}
          data={data}
          pieValue={data => data.ratio}
          cornerRadius={3}
          padAngle={0.005}
        >
          {pie =>
            pie.arcs.map(arc => (
              <g key={arc.data.label}>
                <path
                  className={makeArcStyles(theme, arc.data.label)}
                  d={
                    pie.path({
                      ...arc,
                    }) ?? undefined
                  }
                />
              </g>
            ))
          }
        </Pie>
      </Group>
    </svg>
  );
};

const makeArcStyles = (theme: Theme, type: 'basal' | 'bolus') =>
  css({
    fill:
      type === 'basal'
        ? theme.palette.insulin.basal.contrastText
        : theme.palette.insulin.bolus.contrastText,
    stroke:
      type === 'basal'
        ? theme.palette.insulin.basal.main
        : theme.palette.insulin.bolus.main,
  });
