import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { FontSizes } from '@/assets/fonts';
import { Icons } from '@/assets/icons';
import { Card } from '@/components/card/Card';
import { CardBody } from '@/components/card/CardBody';
import { TextCardTitle } from '@/components/card/CardTitle';
import { AlertIconNotification } from '@/components/notifications/AlertIconNotification';
import { useStyles } from '@/hooks/useStyles';
import {
  AlertCategory,
  AlertSummary,
  UNCLEARABLE_ALERTS,
} from '@/models/AlertModel';
import { usePatientMonitoringContext } from '@/pages/patient-monitoring/PatientMonitoringState.tsx';
import { Queries } from '@/queries/Queries';
import { toDateTime } from '@/utils/date';

export const AlertCategoriesTranslations = {
  hypoglycemia: 'alertConfig.hypoglycemiaEvent',
  hypoglycemia_tbr: 'alertConfig.hypoglycemiaEventTbr',
  hyperglycemia: 'alertConfig.hyperglycemiaEvent',
  hyperglycemia_tar: 'alertConfig.hyperglycemiaEventTar',
  transmission: 'alertConfig.transmission',
  measurement: 'alertConfig.measurement',
  severe_hypoglycemia: 'alertConfig.perceivedSevereHypoglycemia',
  technical_alert: 'alertConfig.technicalAlert',
  remove_consent: 'alertConfig.withdrawalOfConsent',
} as const satisfies Record<AlertCategory, string>;

export type ActiveNotificationsProps = {
  className?: string;
};

export const ActiveNotifications: React.FC<ActiveNotificationsProps> = ({
  className,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const patient = usePatientMonitoringContext(state => state.patient);

  const { data: alerts } = Queries.practitioner.useListPatientAlerts(
    patient.id,
  );
  if (!alerts) {
    return null;
  }
  return (
    <Card className={className} elevation={0}>
      <TextCardTitle title={t('notifications.title')} />
      <CardBody className={styles.cardBody}>
        {alerts.length > 0 ? (
          <NotificationsList notifications={alerts} />
        ) : (
          <Typography variant="body" className={styles.noNotif}>
            {t('notifications.none')}
          </Typography>
        )}
      </CardBody>
    </Card>
  );
};

const NotificationsList = ({
  notifications,
}: {
  notifications: AlertSummary[];
}) => (
  <>
    {notifications.map((notification, idx) => (
      <Notification key={idx} notification={notification} />
    ))}
  </>
);

const Notification = ({ notification }: { notification: AlertSummary }) => {
  const styles = useStyles(makeStyles);
  const dismissAlert = Queries.alerts.useDismissAlert();
  const patient = usePatientMonitoringContext(state => state.patient);

  return (
    <Link
      to={`/patients/${patient.id}/?from=${toDateTime(notification.date)
        .minus({ days: 13 })
        .toISODate()}&to=${toDateTime(notification.date)
        .plus({ days: 1 })
        .toISODate()}`}
      className={styles.notifLink}
    >
      <AlertIconNotification criticity={notification.criticity} />
      <InfoNotification
        date={notification.date}
        category={notification.category}
      />
      <HoverButton
        className={cx({
          [styles.disabledHoverButton]:
            notification.category in UNCLEARABLE_ALERTS,
        })}
        openModal={() =>
          dismissAlert.mutate({
            patientId: patient.id,
            alertId: notification.id,
          })
        }
      />
    </Link>
  );
};

const InfoNotification = ({
  date,
  category,
}: {
  date: string | DateTime;
  category: AlertCategory;
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return (
    <div className={styles.notifContent}>
      <Typography variant="bodySmall">
        {t(AlertCategoriesTranslations[category])}
      </Typography>
      <Typography variant="caption">
        {toDateTime(date).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}
      </Typography>
    </div>
  );
};

/**
 * @deprecated Use MUI Button instead
 */
const HoverButton = ({
  openModal,
  className,
}: {
  openModal: () => void;
  className?: string;
}) => {
  const styles = useStyles(makeStyles);
  const handleClick = async (evt: React.MouseEvent) => {
    // prevent Link to redirect
    evt.preventDefault();

    // prevent Tooltip to autoclose
    evt.stopPropagation();

    openModal();
  };

  return (
    <div
      className={cx('hover-button', className, styles.hoverButton)}
      onClick={handleClick}
    >
      <Icons.check className={styles.icon} />
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  notifLink: css`
    display: flex;
    padding: ${theme.spacing(4)};
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    gap: ${theme.spacing(4)};
    border-radius: ${theme.spacing(1)};
    .hover-button {
      visibility: hidden;
      opacity: 0;
    }
    :hover {
      .hover-button {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.3s;
      }
      background-color: ${theme.palette.background.default};
      transition: background-color 0.3s;
    }
  `,
  notifContent: css`
    display: flex;
    flex-direction: column;
    flex: 1 1;
    margin-left: ${theme.spacing(4)};
    justify-content: flex-start;
  `,
  noNotif: css`
    margin: ${theme.spacing(4)} 0;
    font-style: italic;
    color: ${theme.palette.text.secondary};
  `,
  cardBody: css`
    padding: ${theme.spacing(4)} ${theme.spacing(8)} ${theme.spacing(8)}
      ${theme.spacing(8)};
    display: flex;
    flex-direction: column;
  `,
  disabledHoverButton: css`
    visibility: hidden !important;
  `,
  hoverButton: css`
    flex: 0 0;
    display: flex;
    background-color: ${theme.palette.primary.main};
    border-radius: 100%;
    height: fit-content;
    width: fit-content;
    align-self: center;
    padding: ${theme.spacing(4)};
    :hover {
      background-color: ${theme.palette.primary.light};
    }
  `,
  icon: css`
    color: ${theme.palette.common.white};
    width: ${FontSizes.bodyLarge};
    height: ${FontSizes.bodyLarge};
  `,
});
