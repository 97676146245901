import { UserRepository } from '@/io/repository/UserRepository';
import { stripQueryResult } from '@/queries/Queries';
import { createQuery } from '@/queries/utils/CreateQuery';

export class UserQueries {
  constructor(
    private readonly repository: UserRepository = new UserRepository(),
  ) {}

  /*******************************/
  /*********** QUERIES ***********/
  /*******************************/

  useMe = createQuery(
    'me',
    ({}) => [],
    async () => stripQueryResult(await this.repository.getUser()),
  );

  useFeatureFlags = createQuery(
    'featureFlags',
    ({}) => [],
    async () => stripQueryResult(await this.repository.getFeatureFlags()),
  );
}
