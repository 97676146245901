import React, { useCallback, useEffect, useMemo } from 'react';

import { CardHeader } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { isScrollNearBottom, useScroll } from '@/hooks/useScroll.ts';
import { Patient } from '@/models/PatientModel.ts';
import { Queries } from '@/queries/Queries.ts';
import { DateUtils } from '@/utils/date.ts';

import { BGMContainerBody } from './BGMContainerBody';

export type BGMContainerProps = {
  from: DateTime;
  to: DateTime;
  patient: Patient;
};

const MemoizedBGMBody = React.memo(BGMContainerBody);

export const BGMContainer: React.FC<BGMContainerProps> = ({ to, patient }) => {
  const { t } = useTranslation();
  const isToYesterday = DateUtils.compareDates(to, DateTime.now()) === -1;

  const scroll = useScroll();

  useEffect(() => {
    if (isScrollNearBottom(scroll)) {
      fetchNextPage();
    }
  });

  const bgmRequest = Queries.diabetes.useInfiniteBGMLogbook({
    patientId: patient.id,
    from: to.minus({ days: 30 }).startOf('week'),
    to: isToYesterday ? to.plus({ day: 1 }) : to,
  });

  const data = useMemo(
    () => (bgmRequest.isSuccess ? bgmRequest.data.pages.flat() : []),
    [bgmRequest.isSuccess, bgmRequest.data],
  );

  const fetchNextPage = useCallback(() => {
    bgmRequest.fetchNextPage({ cancelRefetch: false });
  }, []);

  return (
    <Card elevation={0}>
      <CardHeader title={t('bgmLogBook.cardTitle')} />
      <MemoizedBGMBody patientId={patient.id} data={data} />
    </Card>
  );
};
