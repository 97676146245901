import React from 'react';

import { css } from '@emotion/css';
import { Theme, useTheme } from '@mui/material';
import { AxisBottom, AxisLeft, AxisRight } from '@visx/axis';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { Text } from '@visx/text';
import { ScaleLinear } from 'd3-scale';
import { useTranslation } from 'react-i18next';

import { MarginProps } from '@/components/data-representations/DailyGraph/types';
import { AGPStats } from '@/models/AGPStatsModel.ts';
import { DiabetesParameters } from '@/models/DiabetesDataModel';
import * as GRAPH from '@/uiKit/molecules/graphs/AgpProfile/constants';

type DecorationsPropsCommon = {
  height: number;
  margin: MarginProps;
  xScale: ScaleLinear<number, number>;
};
type DecorationsProps = DecorationsPropsCommon & {
  maxY: number;
};

type DecorationsProps2Common = {
  width: number;
  margin: MarginProps;
  yScale: ScaleLinear<number, number>;
};
type DecorationsProps2 = DecorationsProps2Common & {
  maxY: number;
  parameters: DiabetesParameters;
};

export const HorizontalGrids = ({
  width,
  yScale,
  parameters,
}: DecorationsProps2) => {
  const theme = useTheme();

  return (
    <GridRows
      scale={yScale}
      tickValues={[
        0,
        parameters.severeHypoglycemia,
        parameters.hypoglycemia,
        parameters.hyperglycemia,
        parameters.severeHyperglycemia,
      ]}
      width={width}
      offset={0}
      stroke={theme.palette.grey[50]}
      strokeWidth={2}
    />
  );
};

export type TemplateProps = {
  thresholds: DiabetesParameters;
  parentHeight: number;
  parentWidth: number;
  lastData: AGPStats[number];
  children: React.ReactNode;
  scales: {
    rescaleX: ScaleLinear<number, number>;
    rescaleY: ScaleLinear<number, number>;
  };
  maxY: number;
};

export const Template: React.FC<TemplateProps> = ({
  lastData,
  thresholds,
  parentHeight,
  scales,
  children,
  maxY,
}) => {
  const theme = useTheme();
  const { rescaleX, rescaleY } = scales;
  const styles = makeStyles(theme);
  const { t } = useTranslation();

  return (
    <>
      <Text verticalAnchor="middle" className={styles.legendText} dy="0.5em">
        {t('pages.patientMonitoring.agp.glycemiaMgDl')}
      </Text>
      <AgpAxisLeft
        parameters={thresholds}
        yScale={rescaleY}
        maxY={maxY}
        margin={GRAPH.margin}
      />
      <AgpAxisRight
        percentiles={GRAPH.percentiles}
        values={lastData}
        yScale={rescaleY}
        left={rescaleX(GRAPH.HOURS_SCALING - 1)}
      />
      <BottomAxis
        height={parentHeight}
        margin={GRAPH.margin}
        xScale={rescaleX}
        maxY={maxY}
      />
      <Group left={GRAPH.margin.left} top={0}>
        <HorizontalGrids
          yScale={rescaleY}
          width={rescaleX(GRAPH.HOURS_SCALING - 1) - rescaleX(0)}
          margin={GRAPH.margin}
          parameters={thresholds}
          maxY={maxY}
        />
        <Group top={rescaleY(thresholds.hyperglycemia)}>
          <rect
            width={rescaleX(GRAPH.HOURS_SCALING - 1) - rescaleX(0)}
            height={
              rescaleY(thresholds.hypoglycemia) -
              rescaleY(thresholds.hyperglycemia)
            }
            className={styles.agpRange}
          />
        </Group>
      </Group>

      {children}
    </>
  );
};
export const BottomAxis = ({ height, margin, xScale }: DecorationsProps) => {
  const theme = useTheme();

  return (
    <AxisBottom
      top={height - (margin?.bottom ?? 0)}
      stroke={theme.palette.grey[100]}
      scale={xScale}
      hideAxisLine
      tickStroke={theme.palette.grey[100]}
      tickLabelProps={(_, i) => ({
        dy: '0em',
        dx:
          i === 0
            ? '0em'
            : i === Math.round(GRAPH.HOURS_SCALING / 2) - 1
              ? '-1.5em'
              : '-0.5em',
        fill: theme.palette.grey[800],
        fontFamily: 'NunitoSans',
        fontSize: '0.775em',
        textAnchor: 'start',
      })}
      tickFormat={e => ((e + '').length === 1 ? '0' + e + ' h' : e + ' h')}
    />
  );
};

type AgpAxisProps = {
  parameters: DiabetesParameters;
  yScale: ScaleLinear<number, number>;
  left?: number;
  maxY: number;
  margin: MarginProps;
};
export const AgpAxisLeft = ({
  parameters,
  yScale,
  maxY,
  margin,
  left = 0,
}: AgpAxisProps) => {
  const theme = useTheme();

  return (
    <Group left={margin.left}>
      <AxisLeft
        hideAxisLine
        left={left}
        scale={yScale}
        tickValues={[
          0,
          parameters.severeHypoglycemia,
          parameters.hypoglycemia,
          parameters.hyperglycemia,
          parameters.severeHyperglycemia,
          maxY,
        ]}
        hideTicks
        labelProps={{ fill: theme.palette.grey[800] }}
        tickLabelProps={value => ({
          dx: '0.2em',
          dy: '0.35em',
          fill: [parameters.hypoglycemia, parameters.hyperglycemia].includes(
            value as number,
          )
            ? theme.palette.glycemia.normal.light
            : theme.palette.grey[800],
          fontSize: '0.8em',
          textAnchor: 'end',
        })}
      />
    </Group>
  );
};

type AgpAxisRightProps = {
  percentiles: number[];
  yScale: ScaleLinear<number, number>;
  left?: number;
  values: Record<string, number>;
};

export const AgpAxisRight = ({
  percentiles,
  yScale,
  values,
  left = 0,
}: AgpAxisRightProps) => {
  const theme = useTheme();

  return (
    <AxisRight
      hideAxisLine
      left={left}
      scale={yScale}
      tickValues={Object.values(values)}
      tickFormat={(_, i) => `${percentiles[i]}%`}
      tickStroke={theme.palette.blue.main}
      hideTicks
      tickLabelProps={() => ({
        dx: '0.1em',
        dy: '0.3em',
        fill: theme.palette.blue.main,
        fontSize: '0.8em',
        textAnchor: 'start',
      })}
    />
  );
};

const makeStyles = (theme: Theme) => ({
  legendText: css`
    fill: ${theme.palette.grey[800]};
    font-size: 0.875em;
  `,
  agpRange: css`
    color: ${theme.palette.glycemia.normal.light};
    fill: currentColor;
    opacity: 0.1;
  `,
});
