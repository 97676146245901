import * as React from "react";
const SvgManual = (props) => /* @__PURE__ */ React.createElement("svg", { xmlSpace: "preserve", viewBox: "0 0 682.66669 682.66669", xmlns: "http://www.w3.org/2000/svg", stroke: "currentColor", ...props }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(1.3333333,0,0,-1.3333333,0,682.66667)" }, /* @__PURE__ */ React.createElement("g", { clipPath: "url(#clipPath1943)" }, /* @__PURE__ */ React.createElement("g", { transform: "translate(409.43,149.559)" }, /* @__PURE__ */ React.createElement("path", { d: "m 0,0 v -31.498 c 0,-57.099 -46.288,-103.386 -103.386,-103.386 h -0.001 c -34.466,0 -64.989,16.867 -83.773,42.796 -4.717,6.51 -9.42,13.031 -14.351,19.381 l -91.408,117.711 c -8.717,11.224 -6.683,27.389 4.541,36.106 v 0 c 11.224,8.716 27.389,6.683 36.106,-4.542 l 45.499,-58.591 v 9.595 73.724 97.208 c 0,14.211 11.52,25.732 25.732,25.732 v 0 c 14.211,0 25.732,-11.521 25.732,-25.732 V 75.359 102.811 c 0,14.212 11.52,25.732 25.731,25.732 v 0 c 14.212,0 25.732,-11.52 25.732,-25.732 V 75.359 95.136 c 0,14.212 11.52,25.732 25.732,25.732 v 0 c 14.211,0 25.731,-11.52 25.731,-25.732 V 75.359 91.96 c 0,14.465 11.727,26.192 26.192,26.192 v 0 C -11.726,118.152 0,106.425 0,91.96 Z", style: {
  fill: "none",
  strokeWidth: 30,
  strokeLinecap: "round",
  strokeLinejoin: "round"
} })), /* @__PURE__ */ React.createElement("g", { transform: "translate(154.5271,373.7946)" }, /* @__PURE__ */ React.createElement("path", { d: "M 0,0 C 0,40.607 32.918,73.525 73.525,73.525 114.132,73.525 147.05,40.607 147.05,0", style: {
  fill: "none",
  strokeWidth: 30,
  strokeLinecap: "round",
  strokeLinejoin: "round"
} })), /* @__PURE__ */ React.createElement("g", { transform: "translate(102.5701,373.7946)" }, /* @__PURE__ */ React.createElement("path", { d: "M 0,0 C 0,68.224 55.307,123.531 123.531,123.531 191.755,123.531 247.062,68.224 247.062,0", style: {
  fill: "none",
  strokeWidth: 30,
  strokeLinecap: "round",
  strokeLinejoin: "round"
} })))));
export default SvgManual;
