import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';
import { ContinuousInsulinStats } from '@/models/InsulinModel.ts';
import { Dot } from '@/uiKit/atoms/Dot.tsx';
import { InsulinStatsWheel } from '@/uiKit/molecules/graphs/InsulinStatsWheel.tsx';
import { InsulinStatItem } from '@/uiKit/organisms/InsulinStats/InsulinStatItem.tsx';

export type ContinuousInsulinStatsContentProps = {
  className?: string;
  stats: ContinuousInsulinStats;
};

export const ContinuousInsulinStatsContent: React.FC<
  ContinuousInsulinStatsContentProps
> = ({ className, stats }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const theme = useTheme();
  const ratioBasal =
    stats.meanTotalInsulin == 0
      ? 0
      : stats.meanBasalInsulin / stats.meanTotalInsulin;
  const ratioBolus =
    stats.meanTotalInsulin == 0
      ? 0
      : stats.meanBolusInsulin / stats.meanTotalInsulin;

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.stats}>
        <InsulinStatItem
          title={t('insulin.averageNbBolusPerDay')}
          value={stats.meanBolusInjections}
          precision={1}
        />
        <InsulinStatItem
          title={t('insulin.averageInsulinPerDay')}
          value={stats.meanTotalInsulin}
          unit="U"
          precision={1}
        />
      </div>
      <InsulinStatsWheel
        className={styles.wheel}
        basal={stats.meanBasalInsulin}
        bolus={stats.meanBolusInsulin}
      />
      <div className={styles.legend}>
        <InsulinStatItem
          legend={<Dot color={theme.palette.insulin.basal} />}
          title={`${t('insulin.basalInsulinPerDay')}`}
          percentage={ratioBasal}
          percentagePrecision={0}
          value={stats.meanBasalInsulin}
          unit="U"
          precision={1}
        />
        <InsulinStatItem
          legend={<Dot color={theme.palette.insulin.bolus} />}
          title={`${t('insulin.bolusInsulinPerDay')}`}
          percentage={ratioBolus}
          percentagePrecision={0}
          value={stats.meanBolusInsulin}
          unit="U"
          precision={1}
        />
      </div>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: clip;
    gap: ${theme.spacing(8)};
    flex-wrap: wrap;
    align-items: center;
  `,
  stats: css`
    flex: 0 1;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(16)};
    justify-content: space-around;
  `,
  wheel: css`
    flex: 1 1;
    display: flex;
    flex-direction: row-reverse;
    height: 200px;
    align-self: stretch;
    margin: ${theme.spacing(4)};
  `,
  legend: css`
    flex: 0 1;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(16)};
    justify-content: space-around;
  `,
});
