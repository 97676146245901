import { DateTime } from 'luxon';

import {
  ApiDataSourceSpec,
  apiDataSource,
} from '@/io/datasource/ApiDatasource.ts';
import { insulinDecoder, insulinStatsDecoder } from '@/models/InsulinModel.ts';
import { forcedArray } from '@/utils/decoderUtils.ts';

export class InsulinRepository {
  constructor(private readonly datasource: ApiDataSourceSpec = apiDataSource) {}

  getInsulin = (patientId: string, from: DateTime, to: DateTime) => {
    return this.datasource.get(
      `/practitioner/insulin/${patientId}/`,
      forcedArray(insulinDecoder),
      {
        params: {
          start: from.toISO(),
          end: to.toISO(),
        },
      },
    );
  };

  getInsulinStats = (patientId: string, from: DateTime, to: DateTime) => {
    return this.datasource.get(
      `/practitioner/insulin/${patientId}/stats/`,
      insulinStatsDecoder,
      {
        params: {
          start: from.toISO(),
          end: to.toISO(),
        },
      },
    );
  };
}
