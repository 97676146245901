import React from 'react';

import { usePatientMonitoringContext } from '@/pages/patient-monitoring/PatientMonitoringState.tsx';
import { GlycemiaStats } from '@/uiKit/organisms/GlycemiaStats/GlycemiaStats';
import { InsulinStats } from '@/uiKit/organisms/InsulinStats/InsulinStats';

export const MonitoringStats: React.FC = () => {
  const state = usePatientMonitoringContext(state => state);
  return (
    <>
      <GlycemiaStats
        patientId={state.patient.id}
        from={state.from}
        to={state.to}
      />
      <InsulinStats
        patientId={state.patient.id}
        from={state.from}
        to={state.to}
      />
    </>
  );
};
